<template>
	<div class="text">
		<div class="text-header">
			<img class="header-logo" :src="require('../../assets/icons/logo-half.svg')" />
			<div class="header-title">{{ t('Agent.title') }}</div>
		</div>
		<div class="text-body">
			<p>INSHOP.gg, for one part, and the User, who has accepted the Terms of Use of the Website, permanently posted on the Internet Website at https://inshop.gg/tos/, for the other part, have concluded this Agency agreement as follows</p>
			<h1>1. Terms and Definitions</h1>
			<p>
				1.1.
				<br />«Agreement» –The present Agency agreement
				<br />«Inventory» – The Principal's possibility to use the object rendered in the "Inventory" section of the Principal's account on the web-site https://steamcommunity.com, in accordance with the Steam Service Subscriber Agreement, posted on the Internet at: http://store.steampowered.com/subscriber_agreement
				<br />«Contracting party» –Another user who has concluded Deal with the Principal.
				<br />«Report» – The Agent's report on the assignments completed during the Reporting Period.
				<br />«Offer» –The Principal's proposal to conclude Deal with respect to the Inventory owned by the Principal.
				<br />«Princpal» – The User who has accepted the Terms of Use, which are permanently posted on the Internet website at https://inshop.gg/tos/, and concluded the Agreement in the manner provided for in par. 2.1 of the Agreement.
				<br />«Parties» – Agent and Principal.
				<br />«Deal» – The contract aimed at compensatory transfer of the Inventory, concluded between the Principal and the Other User, based upon the conditions stipulated in the Offer.
				<br />«Website» – Collection of information, texts, graphic elements, design, pictures, photo and video and other intellectual property, as well as the software in the information system available on the Internet at a domain address https://inshop.gg/.
			</p>
			<p>
				1.2.
				All the other terms and definitions in the text of the Agreement are interpreted by the Parties in accordance with the effective legislation of the Russian Federation and the Terms of Use, which are permanently posted on inshop.gg the Internet Website at https://inshop.gg/tos/
			</p>
			<p>
				1.3.
				The headings (of articles) of the Treaty are intended solely for the convenience of applying the text of the Treaty and have no literal legal meaning.
			</p>
			<h1>2. Procedure for concluding the Agreement</h1>
			<p>
				2.1.
				The proper acceptance of the Agreement by the Principal is the fulfillment by the Principal of one of the following actions:
			</p>
			<p>
				2.1.1.
				Sending application to the Agent in accordance with paragraph 4.6 of the Agreement;
			</p>
			<p>
				2.1.2.
				Provision of funds to the Agent (electronic funds, electronic checks) in accordance with paragraph 6.1 of the Agreement.
			</p>
			<h1>3. Subject matter of the Agreement</h1>
			<p>
				3.1.
				The Agent undertakes to perform the following actions for remuneration on behalf and at the expense of the Principal:
			</p>
			<p>
				2.1.1.
				Conclude and execute the Deal on behalf of the Principal;
			</p>
			<p>
				2.1.2.
				Accept execution regarding the Deal and transfer it to the Principal.
			</p>
			<p>
				3.2.
				The Principal has rights and obligations for all the Deals concluded by the Agent on behalf of the Principal.
			</p>
			<p>
				3.3.
				The Principal hereby authorizes the Agent to perform all the actions necessary to fulfill the Principal's assignments on behalf of the Principal.
			</p>
			<p>
				3.4.
				The Principal hereby gives the Agent his unconditional consent to simultaneously represent the Principal and any third person who is the Other User by the Agent when making and executing the Deals.
			</p>
			<p>
				3.5.
				The procedure and terms for the Principal's use of the software and the options of the Website and the Personal Account when performing the obligations according to the Agreement are governed by the Terms of Use, which are permanently posted on the Internet website at https://inshop.gg/tos/.
			</p>
			<h1>4. Conclusion and execution of the Deal on behalf of the Principal</h1>
			<p>Acquisition of Inventory</p>
			<p>
				4.1.
				The condition for concluding Deals concerning the acquisition of the Inventory by the Agent on behalf of and at the expense of the Principal is positive remaining balance on the Principal's account reflected in the Personal Account in the amount sufficient to complete the Deal.
			</p>
			<p>
				4.2.
				The Principal, using hardware and software of the Web-site, shall send the instruction to accept the Offer posted on the Web-site to the Agent.
			</p>
			<p>
				4.3.
				The Agent, on behalf of the Principal and in accordance with the instruction sent by the Principal in the manner provided for in paragraph 4.2 of the Agreement, accepts the Offer and accepts execution regarding the Deal from the Contracting party, and the Principal shall be notified thereof in the Personal Account.
			</p>
			<p>
				4.4.
				After receiving the notification in accordance with paragraph 4.3 of the Agreement, the Principal is obliged to take actions to accept the execution from the Agent, received by the Agent from the Contracting party.
			</p>
			<p>
				4.5.
				In the event of failure to fulfill the obligations to accept execution in accordance with paragraph 4.4 of the Agreement within 4 (four) hours from the moment of sending the notification referred to in paragraph 4.3 of the Agreement, the Principal shall send the Agent written request to transfer the Inventory by the mail with the description of attachment and the notification of service. The Agent is obliged within 5 (five) working days to consider such request and inform the Principal in the Personal Cabinet about time and procedure of committing actions which the Principal should comply with for the acceptance of execution.
			</p>
			<p>Alienation of Inventory</p>
			<p>
				4.6.
				The Principal, using the hardware and software of the Web-site, shall send the application specifying the following terms of the Deal to the Agent:
			</p>
			<p>
				4.6.1.
				Inventory;
			</p>
			<p>
				4.6.2.
				Alienation price of the Inventory.
			</p>
			<p>All miscellaneous terms and conditions of the Deal are determined at the discretion of the Agent.</p>
			<p>
				4.7.
				In accordance with the application received by the Agent in the manner provided for in paragraph 4.6 of the Agreement, the Agent on behalf of the Principal places on the Web-site the Offer containing information about the Inventory and the price of alienation of the Inventory and addressed to all Other Users, and the Principal shall be notified thereof in the Personal Account.
			</p>
			<p>
				4.8.
				With regard to certain Other Users to whom the Offer is sent, the Agent is entitled on behalf of the Principal to change the terms of the Offer concerning the sale price of the Inventory by means of its reduction to the amount determined in accordance with the procedure specified in paragraph 5.2 of the Agreement.
			</p>
			<p>
				4.9.
				After acceptance of the Offer by Other User, the Agent shall notify the Principal thereof in the Personal Account.
			</p>
			<p>
				4.10.
				After sending the notification of acceptance of the Offer in accordance with paragraph 4.9 of the Agreement by the Agent, the Principal is obligated to transfer the Inventory to the Agent with the purpose of its further transfer to the Contracting party.
			</p>
			<p>
				4.11.
				After transferring the Inventory in accordance with paragraph 4.10 of the Agreement by the Principal, the Agent accepts the execution regarding the Deal from the Contracting party, and the Agent notifies the Principal thereof in the Personal Account by reflecting the amount credited to the Principal's account.
			</p>
			<h1>5. Remuneration</h1>
			<p>
				5.1.
				In accordance with the Agreement, the Agent's remuneration equals to 5% (five percent) of the amount of funds accounted for in the Personal Account at the time of return of funds to the Principal from the Personal Account in accordance with paragraphs 6.4 and 6.5 of the Agreement.
			</p>
			<p>
				5.2.
				The Agent shall withhold the remuneration provided for in paragraph 5.1 of the Agreement when returning funds to the Principal from the Personal Account in accordance with paragraphs 6.4, 6.5 of the Agreement.
			</p>
			<h1>6. Procedure for crediting with and return of funds from the Personal Account</h1>
			<p>
				6.1.
				The transfer of funds to the Agent occurs through:
			</p>
			<p>
				6.1.1.
				Bank transfer to the account of the Agent or with the help of payment intermediaries (payment agents, billing aggregators) with whom the Agent has concluded relevant contracts;
			</p>
			<p>
				6.1.2.
				Transfer of electronic funds to the Agent's account;
			</p>
			<p>
				6.1.3.
				Transfer of title signs of WebMoney to the Agent's account.
			</p>
			<p>
				6.2.
				The Agent's expenses for carrying out the transfers indicated in paragraph 6.1 of the Agreement, including remuneration of payment agents, billing aggregators, shall be paid in full by the Principal.
			</p>
			<p>
				6.3.
				The Agent shall carry out keeping and accounting in the Personal Cabinet the funds specified by the Principal in accordance with paragraph 6.1 of the Agreement or received by the Principal from the sale of the Inventory on the basis of the Deal having been made by the Principal.
			</p>
			<p>
				6.4.
				The Agent shall return the funds, accounted for in the Personal Account at the request of the Principal, sent using hardware and software of the Web-site, within 3 (three) business days from the moment of receipt of such request.
			</p>
			<h1>7. Reports of the Agent</h1>
			<p>
				7.1.
				The Agent is obliged to provide the Principal with the Report on the Deals made within 1 (one) business day after the conclusion of each Deal.
			</p>
			<p>
				7.2.
				The Report is sent to the Personal Account. The form of the Report is determined at the discretion of the Agent.
			</p>
			<p>
				7.3.
				Within 1 (one) business day from the date of sending the Report, the Principal is entitled to send objections to the Report in the manner provided for in paragraph 9.4 of the Agreement. Upon the expiryof the abovementioned period, if the Principal has not objected to the Report, such Report shall be deemed to have been accepted by the Principal.
			</p>
			<h1>8. Dispute Resolution</h1>
			<p>
				8.1.
				The Parties will endeavor to resolve all disputes, disagreements and claims, which may arise in connection with execution, termination or invalidation of the Agreement, through negotiations. The Party, which has claim and/or disagreement, sends message to the other Party in the manner stipulated by paragraph 9.4 of the Agreement, indicating the arisen claims and/or disagreements. The message should contain the essence of the asserted claim and the evidence confirming the claim.
			</p>
			<p>
				8.2.
				Within 15 (fifteen) business days from the moment of receipt of the said message, the Party, which has received, is obliged to reply to this message.
			</p>
			<p>
				8.3.
				If the reply to the message is not received by the Party, having sent the message, within 30 (thirty) business days from the date of sending corresponding message, or if the Parties do not come to the agreement on the claims and/or disagreements which have arisen, the dispute shall be submitted to the court for consideration at the location of the Agent.
			</p>
			<h1>9. Final provisions</h1>
			<p>
				9.1.
				The Parties hereby confirm that when performing(amending, supplementing, terminating) the Agreement, as well as when maintaining the correspondence regarding the specified issues, the use of analogues of the Parties' autographic signatures is permitted. The Parties confirm that all notifications, messages, agreements and documents within the framework of the Parties’ performance of the obligations arising from the Agreement, having been signed by analogues of the Parties' autographic signatures, shall have legal force and be binding upon the Parties.
			</p>
			<p>
				9.2.
				The Parties agreed to use facsimile reproduction of signatures of the Parties when preparing the necessary documents and claims under the Agreement. The Parties hereby confirm that documents and claims signed by facsimile reproduction of the signature have legal force and are binding for consideration and acceptance by the Parties.
			</p>
			<p>
				9.3.
				The Parties acknowledge that all the messages, sent from authorized e-mail addresses, as well as to the Personal Account of the Principal, shall be deemed to be sent and signed by the Parties, unless otherwise directly specified in such messages.
			</p>
			<p>
				9.4.
				Except for the cases expressly provided by the Agreement and the effective legislation of the Russian Federation, all notifications, messages and documents within the framework of the Parties' performance of the obligations arising from the Agreement shall be forwarded and deemed to be received by the Parties if they are sent by e-mail from the authorized address of one of the Parties to the authorized address of other one. The authorized addresses are:
			</p>
			<p>
				9.4.1.
				For the Agent: support@inshop.gg;
			</p>
			<p>
				9.4.2.
				For the Principal: the e-mail address specified by the Principal in the Personal Account;
			</p>
			<p>
				9.5.
				Prior to the receipt of information from the other Party about breach of the confidentiality of the said information, all actions and documents committed and sent from the authorized address of the Party, even if such actions and documents were committed and sent by other persons, but from the authorized address, are considered committed and sent by the Party of the corresponding authorized address. In this case, such Party has the rights and obligations, as well as bears the responsibility.
			</p>
			<h1>10. Change of terms and termination of the Agreement</h1>
			<p>
				10.1.
				The Agent is entitled to unilaterally change the terms of the Agreement, with such changes coming into effect when the new version of the Agreement is published on the Internet at https://inshop.gg/agent/.
			</p>
			<p>
				10.2.
				Continuation of the Principal's pursuing actions aimed at making Deals shall mean the Principal's consent with the terms of the new version of the Treaty. If the Principal does not agree with the terms of the new version of the Treaty, he ceases to carry out these actions and make Deals.
			</p>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
</script>
<style scoped>
@import url("../../assets/styles/text.css");
</style>
